<template>
  <div
    v-if="data"
    :class="$style.root"
  >
    <LayoutHeadingComponent
      :title="page?.title"
    />

    <CoreBlocksComponent
      v-if="page?.blocks"
      :blocks="page?.blocks"
    />
</div>
</template>

<script lang="ts" setup>
const props = defineProps({
  data: Object
})

const page = baseStrapiGetAttributes(props.data)
</script>

<style module>
.root {
  composes: container from global;
  padding: var(--padding--container);
}
</style>
