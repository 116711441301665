<template>
  <section v-if="data">
    <PagesSlugComponent :data="data" />
  </section>
  <SharedErrorComponent v-else-if="error" :error="error" />
</template>

<script lang="ts" setup>

const queryOptions = baseStrapiGetSlugQueryObjectFromRoute()
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryPageEntryBySlug(queryOptions) } }
  )
})

basePageSetHead(data)

const router = useRouter()
if (error.value || !data?.value?.data?.length) {
  router.push('/')
}
</script>
